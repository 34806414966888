import styled from "styled-components";

const StyledComponent = styled.div`
  text-align: -webkit-center;
  display: grid;
  flex: 1;
  
 form {
    width: 100%;

    .MuiFilledInput-input {
      color: white;
    }


    .MuiFormLabel-root {
      color: white;
    } 

    .MuiFormLabel-root.Mui-focused {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .ResponsiveBackgroundImage {
    background-size: cover;
  }

  .MuiBox-root {
    display: inline;
    place-self: stretch;
    align-items: center;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: #6c7e91;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #ffffff;
  }

  .registerForm {
    display: flex;
    max-width: 700px;
    margin-top: 32px;
    align-items: center;
    flex-direction: column;
    .MuiFormControl-marginNormal {
      width: 100%;
    }
  }

  .title {
    font-size: 3.125rem;
    align-self: flex-start;
    font-weight: bold;
    text-align: start;
    font-weight: 700;
    margin-bottom: 0;
    color: #ffffff;
  }

  .description {
    align-self: flex-start;
    text-align: start;
    margin-bottom: 16px;
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
  }

  .emailReminder {
    font-size: 0.8rem;
    margin-top: 0;
    font-style: italic;
    align-self: start;
    text-align: start;
    color: #ffffff;
    margin-bottom: 16px;
  }

  .MuiCheckbox-root {
    color: #ffffff;
  }

  .emailCheckboxGrid {
    align-items: center;
    display: flex;
    align-self: flex-start;
  }

  .emailCheckbox {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #ffffff;
  }

  .button {
    align-items: center;
    margin-bottom: 16px;
  }

  .alertBox {
    width: 100%;
  }
`;

export default StyledComponent;
