import React from "react";
import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";
import { Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { useRegistration } from "../RegistrationProvider";

const Alert = styled.div`
  background-color: rgb(24, 6, 5);
  color: rgb(250, 179, 174);
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  display: flex;
  align-items: center;
  border-radius: 4px;

  .icon {
    margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
    color: #f44336;
  }
`;

const ApiErrors = ({ error }) => {
  const { t } = useTranslation();
  const { apiErrors } = useRegistration();

  if (error) {
    return (
      <Alert className="alertBox">
        <ErrorIcon className="icon" />
        <Typography>{error}</Typography>
      </Alert>
    );
  }

  return (
    <>
      {apiErrors && apiErrors.length > 0 && (
        <Alert>
          <ErrorIcon className="icon" />
          {apiErrors.map((error) => {
            if (error.code === 2010) {
              return (
                <Typography key={error.field}>
                  {t("error.email_already_used")}
                </Typography>
              );
            }

            return <Typography key={error.field}>{error.message}</Typography>;
          })}
        </Alert>
      )}
    </>
  );
};

export default ApiErrors;
