import React from "react";
import { RichText } from "prismic-reactjs";

import { Grid, Typography } from "@material-ui/core";
import StyledComponent from "./RegistrationSmokeTestForm.style";
import Form from "./Form";
import { ResponsiveBackgroundImage } from "../../Image";
import Container from "../../Container";

const RegistrationSmokeTestForm = ({ pageData, lang, location }) => {
  return (
    <StyledComponent>
      <ResponsiveBackgroundImage image={pageData.registration_background} cover>
        <Container maxWidth="md" disableGutters>
          <Grid container direction="column" className="registerForm">
            {!location?.search.includes("registration_success=1") && (
              <>
                <Typography className="title" variant="h1">
                  {RichText.asText(pageData.step_1_title.raw)}
                </Typography>
                <Typography
                  className="description"
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  {RichText.render(pageData.step_1_description.raw)}
                </Typography>
                <Form pageData={pageData} lang={lang} />
              </>
            )}

            {location?.search.includes("registration_success=1") && (
              <>
                <Typography className="title" variant="h1">
                  {RichText.asText(pageData.registration_success_title.raw)}
                </Typography>
                <Typography
                  className="description"
                  variant="body1"
                  component="div"
                  gutterBottom
                >
                  {RichText.render(pageData.registration_success_text.raw)}
                </Typography>
              </>
            )}
          </Grid>
        </Container>
      </ResponsiveBackgroundImage>
    </StyledComponent>
  );
};

export default RegistrationSmokeTestForm;
