import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import { RichText } from "prismic-reactjs";

import { TextField, Typography } from "@material-ui/core";
import Button from "@landingpage/core/src/components/Button";
import axios from "axios";
import ApiErrors from "../components/ApiErrors";

const Form = ({ pageData, lang }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string(),
        email: yup
          .string()
          .email(t("validation.invalid_email"))
          .required(t("validation.field_required")),
      }),
    []
  );

  const { control, errors, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.GATSBY_APP_URL}/.netlify/functions/SmokeTestRegistration`,
        { ...data, registration_country: lang }
      );

      if (res.status === 200) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          smoketest_registration_success: 1,
        });
        navigate("?registration_success=1");
      }
      setLoading(false);
      setError(null);
    } catch (error) {
      setLoading(false);
      setError(t("error.exception"));
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && <ApiErrors error={error} />}

      <Controller
        helperText={errors?.name?.message}
        error={Boolean(errors?.name?.message)}
        as={TextField}
        placeholder={RichText.asText(pageData.first_name_placeholder.raw)}
        label={RichText.asText(pageData.first_name_placeholder.raw)}
        name="name"
        variant="filled"
        margin="normal"
        control={control}
        defaultValue=""
      />
      <Controller
        helperText={errors?.email?.message}
        error={Boolean(errors?.email?.message)}
        as={TextField}
        placeholder={RichText.asText(pageData.emailadres_placeholder.raw)}
        name="email"
        variant="filled"
        margin="normal"
        label={RichText.asText(pageData.emailadres_placeholder.raw)}
        control={control}
        defaultValue=""
      />
      <Typography
        className="emailReminder"
        variant="body1"
        component="div"
        gutterBottom
      >
        {RichText.asText(pageData.emailadres_reminder.raw)}
      </Typography>
      <Button
        className="button"
        color="primary"
        type="submit"
        margin="normal"
        loading={loading}
        fullWidth
      >
        {t("form.go_on")}
      </Button>
    </form>
  );
};

export default Form;
